import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { Button, Drawer, List, ListItem, ListItemText, IconButton, AppBar, Toolbar, Typography, Box, CssBaseline, ListItemIcon, Divider, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import classes from './SideMenu.module.css';
import { styled } from '@mui/material/styles';

import ArticleIcon from '@mui/icons-material/Article';
import ViewListIcon from '@mui/icons-material/ViewList';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import LockOpenSharpIcon from '@mui/icons-material/LockOpenSharp';
import CommentIcon from '@mui/icons-material/Comment';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ApiIcon from '@mui/icons-material/Api';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';

import Options from '../Options/Options';
import Fields from '../Fields/Fields';
import Sections from '../Sections/Sections';
import Forms from '../Forms/Forms';
import EditUsers from '../EditUsers/EditUsers';
import SubscriptionsComponent from '../SubscriptionsComponent/SubscriptionsComponent';
import Settings from '../Settings/Settings';
// import Integrations from '../Integrations/Integrations';
import logoNew from '../../common/images/FreeForm-Logo_Bold.png';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import apiGatewayService from '../../common/services/apiGatewayService';
import FormLimits from '../FormLimits/FormLimits';

const MyDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isOptions, setIsOptions] = useState(false);
  const [isFields, setIsFields] = useState(false);
  const [isSections, setIsSections] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isEditUsers, setIsEditUsers] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [userGroupName, setUserGroupName] = useState(null);
  const [isSettings, setIsSettings] = useState(false);
  const [isIntegration, setIsIntegration] = useState(false);
  const [isFormDropDown, setIsFormDropDown] = useState(false);
  const [isGroups, setIsGroups] = useState(false); // New state for Groups option
  const [isSubmitted, setIsSubmitted] = useState(false); // New state for Submitted option
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setselectedGroupId] = useState('');
  

  const navigate = useNavigate();
  const location = useLocation(); // New hook to access the current location


  useEffect(() => {
    const hash = location.hash.replace('#', '');
    console.log("hash: ", hash);

    setAllFalse(); // Reset all states

    switch (hash) {
      case 'forms':
        setIsForms(true);
        break;
      case 'fields':
        setIsFields(true);
        break;
      case 'sections':
        setIsSections(true);
        break;
      case 'options':
        setIsOptions(true);
        break;
      case 'edit-users':
        setIsEditUsers(true);
        break;
      case 'subscription':
        setIsSubscription(true);
        break;
      case 'settings':
        setIsSettings(true);
        break;
      case 'integrations':
        setIsIntegration(true);
        break;
      case 'submitted':
        setIsSubmitted(true);
        break;
      default:
        break;
    }
  }, [location.hash]);

  useEffect(() => {
    const user = localStorageService.getObject('user');
    setCognitoUser(user);
    if (user === null) {
      navigate('/');
    } else {
      setUserGroupName(utilityFunctions.getPrimaryUserGroup(user));
    }

    const getFormGroups = async () => {
      try {
        const response = await apiGatewayService.getFormgroups();
        const dataWithUpdatedKeyAndContent = response.data.sort((a, b) => a.groupName.localeCompare(b.groupName));
        const tempGroups = dataWithUpdatedKeyAndContent.map((data) => ({
          text: data.groupName || '',
          icon: <CircleIcon sx={{ fontSize: '15px', padding: '0px' }} />,
          onClick: () => handleFormGroupSelected(data.groupId)
        }));
        setGroups(tempGroups);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    };
    getFormGroups();
  }, []);

  const setAllFalse = () => {
    setIsOptions(false);
    setIsFields(false);
    setIsSections(false);
    setIsForms(false);
    setIsEditUsers(false);
    setIsSubscription(false);
    setIsSettings(false);
    setIsIntegration(false);
    setIsGroups(false);
    setIsSubmitted(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'click') {
      setDrawerOpen(!drawerOpen);
    }
  };

  const handleOptionsSelection = () => {
    // setAllFalse();
    // setIsOptions(true);
    navigate('/sideMenu/#options');
  };

  const handleFieldsSelection = () => {
    // setAllFalse();
    // setIsFields(true);
    navigate('/sideMenu/#fields');
  };

  const handleSectiosSelection = () => {
    // setAllFalse();
    // setIsSections(true);
    navigate('/sideMenu/#sections');
  };

  const handleFormsSelection = () => {
    // setAllFalse();
    // setIsForms(true);
    setselectedGroupId('');
    // toggleIsFormDropDown();
    navigate('/sideMenu/#forms');
  };

  const handleEditUsersSelection = () => {
    // setAllFalse();
    // setIsEditUsers(true);
    navigate('/sideMenu/#edit-users')
  };

  const handleLogOut = () => {
    localStorageService.removeObject('user');
    navigate('/');
  };

  const handleSubscription = () => {
    setAllFalse();
    setIsSubscription(true);
    navigate('/sideMenu/#subscription');
  };

  const handleSettings = () => {
    setAllFalse();
    setIsSettings(true);
    navigate('/sideMenu/#settings');
  };

  const handleIntegrations = () => {
    setAllFalse();
    setIsIntegration(true);
    navigate('/sideMenu/#integrations');
  };

  const handleGroupsSelection = () => {
    setIsGroups(!isGroups);
  };

  const handleSubmittedSelection = () => {
    setAllFalse();
    setIsSubmitted(true);
  };

  const toggleIsFormDropDown = () => {
    setIsFormDropDown(!isFormDropDown);
  };

  const handleFormGroupSelected = (groupId) => {
    setselectedGroupId(groupId);
  };

  const updateFormGroups = async () => {
    try {
      const response = await apiGatewayService.getFormgroups();
      console.log("get form groups response: ", response);
      const dataWithUpdatedKeyAndContent = response.data.sort((a, b) => a.groupName.localeCompare(b.groupName));
      const tempGroups = dataWithUpdatedKeyAndContent.map((data) => ({
        text: data.groupName || '',
        icon: <CircleIcon sx={{ fontSize: '15px', padding: '0px' }} />,
        onClick: () => handleFormGroupSelected(data.groupId)
      }));
      setGroups(Array.from(tempGroups));
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  }

  const sideMenuOptions = [
    { text: 'Forms', icon: <ArticleIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleFormsSelection },
    // { text: 'Sections', icon: <ViewListIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleSectiosSelection },
    // { text: 'Fields', icon: <FormatListBulletedIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleFieldsSelection },
    // { text: 'Options', icon: <ChecklistIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleOptionsSelection },
    // { text: 'Edit Users', icon: <GroupAddIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleEditUsersSelection },
    // { text: 'Subscription', icon: <CardMembershipIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleSubscription },
    { text: 'Settings', icon: <SettingsIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleSettings },
    // { text: 'Integrations', icon: <ApiIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: handleIntegrations },
    { text: 'Feedback', icon: <CommentIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: () => alert('Feature in progress') },
    { text: 'Request', icon: <AddToQueueIcon sx={{ fontSize: '30px', padding: '0px' }} />, onClick: () => alert('Feature in progress') },
  ];

  return (
    <Box sx={{ display: 'flex', minWidth: '800px' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar sx={{ backgroundColor: 'white', color: 'var(--app-color)' }} variant="dense">
          <Avatar src={logoNew} alt="Logo" sx={{ marginLeft: '-25px', marginRight: '0px', width: '180px', height: '65px' }} />
          {drawerOpen ? (
            <IconButton sx={{ marginLeft: "-15px", marginTop: '5px' }} edge="start" color="var(--app-color)" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuOpenIcon sx={{ fontSize: 36, color: "var(--app-color)" }} />
            </IconButton>
          ) : (
            <IconButton sx={{ marginLeft: "-15px", marginTop: '5px' }} edge="start" color="var(--app-color)" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon sx={{ fontSize: 34, color: "var(--app-color)" }} />
            </IconButton>
          )}

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ marginLeft: "auto", marginRight: '-5px', '&:hover': { backgroundColor: 'transparent', color: 'var(--button-hover-color)' } }} edge="end" color="inherit" aria-label="menu" onClick={() => { }}>
              <PersonIcon sx={{ fontSize: 35 }} />
            </IconButton>
            <Typography variant="body1" sx={{ marginRight: '20px', fontWeight: 'bold' }}>
              {userGroupName}
            </Typography>
            <Button onClick={handleLogOut} sx={{ textTransform: 'none', width: '90px', marginRight: 0.1, backgroundColor: 'var(--app-color)', color: 'white', padding: 1, borderRadius: '10px', ':hover': { backgroundColor: 'var(--button-hover-color)', borderColor: 'var(--app-color)' } }}>
              LOG OUT
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
        <Drawer
          variant="persistent"
          anchor="left"
          open={true}
          ModalProps={{
            keepMounted: true,
            disableBackdropClick: true,
            BackdropProps: {
              sx: {
                backgroundColor: 'transparent',
              },
            },
          }}
          sx={{
            '& .MuiDrawer-paper': {
              top: '65px',
              minWidth: (drawerOpen ? '200px' : '0px'),
              backgroundColor: '#2b4252',
              borderTop: '0px solid white',
            },
          }}
        >
          <div role="presentation">
            <List>
              {sideMenuOptions.map((option, index) => (
                <div key={index}>
                  <ListItem
                    button
                    sx={{
                      padding: '0px',
                      margin: '0px',
                      marginTop: '10px',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'var(--button-hover-color)',
                        color: 'white',
                      },
                    }}
                    onClick={option.onClick}
                  >
                    <ListItemIcon sx={{ margin: '0px', marginTop: '0px', padding: '10px', color: 'white' }}>
                      {option.icon}
                    </ListItemIcon>
                    {drawerOpen && (
                      <>
                        <ListItemText
                          sx={{ marginLeft: '10px' }}
                          primary={option.text}
                          primaryTypographyProps={{ fontSize: '20px' }}
                        />
                        {option.text === 'Forms' && (
                          <IconButton
                            sx={{ color: 'white', marginLeft: 'auto' }}
                            onClick={toggleIsFormDropDown}
                          >
                            {isFormDropDown ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                          </IconButton>
                        )}
                      </>
                    )}
                  </ListItem>
                  {option.text === 'Forms' && (isFormDropDown && drawerOpen) && (
                    <List sx={{ paddingLeft: '20px', marginTop: '-20px' }}>
                      <ListItem
                        button
                        sx={{
                          padding: '0px',
                          margin: '0px',
                          marginTop: '10px',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'var(--button-hover-color)',
                            color: 'white',
                          },
                        }}
                        onClick={handleGroupsSelection}
                      >
                        {/* <ListItemIcon sx={{ margin: 0, padding: 0, color: 'white' }}>
                          <RemoveIcon></RemoveIcon>
                        </ListItemIcon> */}
                        <ListItemText sx={{ marginLeft: '50px' }} primary="Groups" primaryTypographyProps={{ fontSize: '15px' }} />
                        <IconButton sx={{ marginLeft: 'auto', marginRight: '0px' , color: 'white'}}>
                          {isGroups ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                      </ListItem>
                      {isGroups && (
                        <List sx={{ paddingLeft: '20px', marginTop: '-10px' , paddingBottom: 0, marginBottom: 0}}>
                          {groups.map((option2, index2) => (
                            <ListItem
                              button
                              key={index2}
                              sx={{
                                padding: '0px',
                                margin: '0px',
                                marginTop: '0px',
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: 'var(--button-hover-color)',
                                  color: 'white',
                                },
                              }}
                              onClick={option2.onClick}
                            >
                              <ListItemIcon sx={{ margin: '0px', marginTop: '0px', marginLeft: '45px', padding: '0px', color: 'white' }}>
                                {option2.icon}
                              </ListItemIcon>
                              <ListItemText sx={{ marginLeft: '-30px' , paddingBottom: 0}} primary={option2.text} primaryTypographyProps={{ fontSize: '15px' }} />
                            </ListItem>
                          ))}
                        </List>
                      )}
                      {/* <ListItem
                        button
                        sx={{
                          padding: '0px',
                          margin: '0px',
                          marginTop: '10px',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'var(--button-hover-color)',
                            color: 'white',
                          },
                        }}
                        onClick={handleSubmittedSelection}
                      >
                        <ListItemText sx={{ marginLeft: '10px' }} primary="Submitted" primaryTypographyProps={{ fontSize: '15px' }} />
                      </ListItem> */}
                    </List>
                  )}
                </div>
              ))}
            </List>
          </div>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "50px", marginLeft: (drawerOpen ? '210px' : '60px') }}>
          {isOptions && <Options />}
          {isFields && <Fields />}
          {isSections && <Sections />}
          {isForms && <Forms selectedGroupId={selectedGroupId} updateFormGroups = {updateFormGroups} />}
          {isEditUsers && <EditUsers isEditUsers={isEditUsers} />}
          {isSubscription && <SubscriptionsComponent />}
          {isSettings && <Settings />}
          {/* {isIntegration && <Integrations />} */}
          {isSubmitted && <div>Submitted Forms View</div>} {/* Example component for Submitted view */}
          {(!isOptions && !isFields && !isSections  && !isForms && !isEditUsers && !isSubscription && !isSettings && !isIntegration && !isSubmitted) && <FormLimits/> }
        </Box>
      </Box>
    </Box>
  );
};

export default MyDrawer;