import React, { useState, useEffect } from 'react';
import { CircularProgress, Box, Button, IconButton, Typography, 
Container, Toolbar, AppBar, Paper, TextField, Table, TableBody, 
TableCell, TableContainer, TableHead, TableRow, Tooltip, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ArticleIcon from '@mui/icons-material/Article';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PublishIcon from '@mui/icons-material/Publish';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';

import FormsModal from '../FormsModal/FormsModal';
import apiGatewayService from '../../common/services/apiGatewayService';
import validationService from '../../common/validationService.service';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import CircularLoader from '../Loader/Loader';
import SearchIcon from '@mui/icons-material/Search';

const FormsSubmitted = (props) => {
  const [forms, setForms] = useState([]);
  const [filteredForms, setFilteredForms] = useState([]);
  const [filters, setFilters] = useState({ formId: '', userEmail: '', submissionId: '' });
  const [isAddNew, setIsAddNew] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isViewJSON, setIsViewJSON] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [cogntioUser, setCogntioUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAcess, setIsOnlyReadAccess] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setOpen(false);
    await getSubmittedFormsData();
  };

  useEffect(() => {
    const getSubmittedFormsData = async () => {
      setIsLoading(true);
      const response = await apiGatewayService.getSubmittedFormsById(props.formId);
      console.log("response: ", response);
      setForms(response.data);
      setFilteredForms(response.data);
      setIsLoading(false);
    };

    const user = localStorageService.getObject('user');
    setCogntioUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    getSubmittedFormsData();
  }, []);

  const getSubmittedFormsData = async () => {
    setIsLoading(true);
    const response = await apiGatewayService.getSubmittedFormsById(props.formId);
    setForms(response.data);
    setFilteredForms(response.data);
    setIsLoading(false);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    console.log("name: ",name);
    console.log("value: ", value);
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));

    const newFilteredForms = forms.filter((form) => {
      return (
        (filters.formId ? form.formId.includes(filters.formId) : true) &&
        (filters.userEmail ? form.userEmail.includes(filters.userEmail) : true) &&
        (filters.submissionId ? form.submissionId.includes(filters.submissionId) : true) &&
        (filters.type ? form.type.includes(filters.type) : true)
      );
    });

    setFilteredForms(newFilteredForms);
  };

  const setAllFalse = () => {
    setIsAddNew(false);
    setIsImport(false);
    setIsViewJSON(false);
    setIsView(false);
    setIsEdit(false);
    setIsViewIndex(null);
  };

  const handleAddNew = () => {
    setAllFalse();
    setIsAddNew(true);
    handleOpen();
  };

  const handleImport = () => {
    setAllFalse();
    setIsImport(true);
    alert("This feature is in progress.");
  };

  const handleViewJSON = (index) => {
    console.log("Inside view Index is: ", index);
    setAllFalse();
    setIsViewJSON(true);
    setIsViewIndex(index);
    handleOpen();
  };

  const handleView = (index) => {
    console.log("Inside view Index is: ", index);
    setAllFalse();
    localStorageService.setObject('isCurrForm', 'true');
    localStorageService.setObject('formId', props.formId);
    localStorageService.setObject('submittedFields', forms[index].fields);
    const formId = forms[index].formId;
    const submittedFormId = forms[index].submissionId;
    const newTab = window.open(`/displayFormFilled/${formId}/${submittedFormId}`, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;
  };

  const handlePrintPreView = (index) => {
    console.log("Inside view Index is: ", index);
    setAllFalse();
    localStorageService.setObject('isCurrForm', 'true');
    localStorageService.setObject('formId', props.formId);
    localStorageService.setObject('submittedFields', forms[index].fields);
    const formId = forms[index].formId;
    const submittedFormId = forms[index].submissionId;
    const newTab = window.open(`/printPreview/${formId}/${submittedFormId}`, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;
  };

  const handleEdit = (index) => {
    setAllFalse();
    console.log("Inside Edit Index Is: ", index);
    setIsEdit(true);
    setIsViewIndex(index);
    console.log(forms[index]);
    console.log("IsViewIndex: ", viewIndex);
    handleOpen();
  };

  const handleDelete = async (index) => {
    console.log("forms: ", forms);
    try {
      const postData = {
        formNames: [forms[index].formHeaders.ID],
      };
      await apiGatewayService.deleteFormsData(postData);
      setAllFalse();
      const formsNew = forms.filter((option, index2) => index2 !== index);
      setForms(Array.from(formsNew));
      setFilteredForms(Array.from(formsNew));
    } catch (error) {
      console.log("Error: ", error);
      alert('Form cannot be deleted');
    };
  };

  const handlePublishForm = async (index) => {
    console.log("index: ", index);
    try {
      const postData = {
        formNames: [decodeURIComponent(forms[index].formHeaders.ID)],
      };
      await apiGatewayService.postPublishFormsData(postData);
      setAllFalse();
    } catch (error) {
      console.log("Error: ", error);
      alert('Form cannot be published.');
    };
  };

  const handleCloseFormsSubmittedView = () => {
    props.handleCloseFormsSubmittedView();
  };

  return (
    <Container disableGutters id = 'Settings' sx={{pl:0, pr: 0, margin: 0, minWidth: '100%', height: '90%', display: 'flex', flexDirection: 'column'}}>      
      <Toolbar sx={{ backgroundColor: 'var(--app-color-secondary)', borderRadius: "10px", padding: '0px', color: 'white' }}>
        <IconButton sx={{ marginLeft: '-20px' }} edge="start" color="inherit" aria-label="menu">
          <ArticleIcon />
        </IconButton>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {`${props.formId}`}
        </Typography>
        <Button
          sx={{
            marginRight: '10px',
            ":hover": { backgroundColor: '#F0F4F4', color: '#2b4252' },
            '&.Mui-disabled': { backgroundColor: '#e0e0e0', color: 'grey' },
          }}
          color="inherit"
          startIcon={<ArrowBackIcon />}
          onClick={handleCloseFormsSubmittedView}
          disabled={isOnlyReadAcess}
        >
          Back to Forms
        </Button>
      </Toolbar>

      {isLoading ? (
        <CircularLoader />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="submitted forms table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Form ID"
                    size="small"
                    name="formId"
                    value={filters.formId}
                    onChange={handleFilterChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="User Email"
                    size="small"
                    name="userEmail"
                    value={filters.userEmail}
                    onChange={handleFilterChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Submission Id"
                    value={filters.submissionId}
                    onChange={handleFilterChange}
                    size="small"
                    name="submissionId"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="type"
                    value={filters.type}
                    onChange={handleFilterChange}
                    size="small"
                    name="type"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredForms.map((form, index) => (
                <TableRow key={index}>
                  <TableCell>{form.formId}</TableCell>
                  <TableCell>{form.userEmail}</TableCell>
                  <TableCell>{form.submissionId}</TableCell>
                  <TableCell>{form.type}</TableCell>
                  <TableCell align="right">
                    {/* <Tooltip title="Publish Form">
                      <IconButton sx={{ color: '#2b4252' }} aria-label="view" onClick={() => handlePublishForm(index)}>
                        <PublishIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View JSON">
                      <IconButton sx={{ color: '#2b4252' }} aria-label="json" onClick={() => handleViewJSON(index)}>
                        <DataObjectIcon />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip title="View Form">
                      <IconButton onClick={() => handleView(index)}>
                        <VisibilityIcon sx={{ fontSize: '30px' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Print Preview">
                      <IconButton onClick={() => handlePrintPreView(index)}>
                        <PrintIcon sx={{ fontSize: '30px' }} />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Edit Form">
                      <IconButton sx={{ color: '#2b4252' }} aria-label="edit" onClick={() => handleEdit(index)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Form">
                      <IconButton sx={{ color: '#CC3824' }} aria-label="delete" onClick={() => handleDelete(index)} disabled={isOnlyReadAcess}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Modal components */}
      {isAddNew && <FormsModal open={open} handleClose={handleClose} form={{}} />}
      {isEdit && <FormsModal open={open} handleClose={handleClose} form={forms[viewIndex]} isViewJSON={null} />}
      {isViewJSON && <FormsModal open={open} handleClose={handleClose} form={forms[viewIndex]} isViewJSON={true} />}
      {isView && <FormsModal open={open} handleClose={handleClose} form={forms[viewIndex]} isViewJSON={true} />}
    </Container>
  );
};

export default FormsSubmitted;